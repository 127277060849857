<template>
  <div class="container"
       :style="{ zoom: zoom }">
    <div class="menu-top flexDisplay"
         :class="{ 'z-scroll': isScroll }">
      <div class="menu-box">
        <div class="flex flex-aic">
          <img @click="$router.push('/')"
               class="menu-logo"
               src="../assets/img/sd-dhlogo.png" />
          <div class="home"
               @click="$router.push('/')">{{base.index || ''}}</div>
        </div>

        <div class="tab-box">
          <div class="ul-login">
            <div class="li"
                 @click="showRegister"
                 v-show="!userinfo">
              {{ userinfo ? userinfo.name : base.register }}｜
            </div>
            <div class="li"
                 @click="showLogin"
                 :disabled="userinfo"
                 v-show="!userinfo">
              {{ base.login || "" }}
            </div>
            <div v-if="userinfo"
                 class="li">{{ userinfo.name }}｜</div>
            <div v-if="userinfo"
                 @click="logout"
                 class="li">
              {{ base.out || "" }}
            </div>
          </div>
          <div @click="cutLang"
               class="cut-lab">{{ language == "EN" ? "CH" : "EN" }}</div>
        </div>
      </div>
    </div>

    <div class="placeholder"></div>
    <!-- 内容 -->
    <!-- 信息 -->
    <div :style="{'margin-top':screenHeight+'px'}">
      <div class="live-info">
        {{subject}}
        <!-- <span>{{state == 1 ? '直播' : '回放'}}</span> -->
      </div>

      <div class="main-content">

        <iframe border="0"
                allowfullscreen="true"
                :src="videoSrc"
                width="100%"
                height="100%"></iframe>
      </div>

      <!-- 浮窗右侧 -->
      <div class="float-menu-right">
        <div class="float-btns">
          <div v-if="isShowFloatRight"
               class="float-icons"
               style="padding-top:10px">
            <div class="register—num"
                 style="display: none;">
              <div>{{ base.fd_reg || "" }}</div>
              <div>{{ base.register_number || "" }}</div>
            </div>
            <div class="float-icon"
                 @click="isShowShare = true">
              <p @click="showRightPop('fx')">
                <img src="../assets/img/sd-share.png" />{{
                    base.fd_share || ""
                }}
              </p>
              <div v-show="isShowRigthPop.fx"
                   class="pop-up aaaa">
                <div class="keep-out"></div>
                <span>{{ base.fd_share || "" }}</span>
                <!-- <img src="../assets/img/fxm.png" style="display: block" /> -->
                <div class="share-link between">
                  <p class="flexq">http://ica2022.shuibeigroup.com/</p>
                  <span class="fz"
                        @click="doCopy('http://ica2022.shuibeigroup.com/')">{{ base.copy_link || "" }}</span>
                </div>
                <Share :facebook='true'
                       :weChat='true'
                       :linkedin='true'
                       :twitter='true'
                       :QQ='false'
                       :weibo='false'
                       :QZone='false'></Share>
              </div>
            </div>

            <div class="float-line"></div>

            <div class="float-icon"
                 style="display: none;"
                 @click="isShowAsk = true">
              <p @click="showRightPop('zszx')">
                <img src="../assets/img/sd-zszx.png" />{{ base.fd_zs || "" }}
              </p>
              <div v-show="isShowRigthPop.zszx"
                   class="pop-up">
                <div class="keep-out"></div>
                <span>{{ base.fd_zs || "" }}</span>
                <div class="lxwm">{{ base.alert_jqqd || "" }}</div>
              </div>
            </div>

            <div class="float-line"
                 style="display: none;"></div>

            <div class="float-icon"
                 @click="isShowConcat = true">
              <p @click="showRightPop('qgp')">
                <img src="../assets/img/sd-gp.png" />
                <!-- <span v-if="!isShowPay">{{ base.fd_qgp || "" }}</span> -->
                <span>vip {{ userinfo.name }}</span>
              </p>
              <!-- 登录后才展示这部分内容  0 展示支付页面  1展示支付后页面-->
              <div v-if="!isShowPay">
                <div v-show="isShowRigthPop.qgp"
                     class="pop-up buy-ticket-box">
                  <div class="keep-out"></div>
                  <div class="title">{{ base.alert_vip_pay || "" }}</div>
                  <img class="buy-ticket-img"
                       :src="base.pc_vip_image"
                       alt="" />
                  <div class="buy-way">
                    <div class="left">
                      <p class="t">{{ base.alert_vip_title || "" }}</p>
                      <p class="p">
                        {{ base.moneySymbol }} {{ base.money || "" }}
                      </p>
                    </div>
                    <div class="cut-off"></div>
                    <div class="right">
                      <p class="t">支付方式</p>
                      <div class="b">
                        <div @click="pay('zfb')">
                          <img src="../assets/img/sd-zfb.png"
                               alt="支付宝支付" />
                        </div>
                        <div @click="pay('wx')">
                          <img src="../assets/img/sd-wx.png"
                               alt="微信支付" />
                        </div>
                        <div @click="pay('paypal')">
                          <img src="../assets/img/sd-myzf.png"
                               alt="支付" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="float-line"
                 style="display: none;"></div>

            <div class="float-icon"
                 style="display: none;"
                 @click="isShowConcat = true">
              <p @click="showRightPop('pwzx')">
                <img src="../assets/img/sd-pwzx.png" />{{ base.fd_pgzx || "" }}
              </p>
              <div v-show="isShowRigthPop.pwzx"
                   class="pop-up">
                <div class="keep-out"></div>
                <span>{{ base.fd_pgzx || "" }}</span>
                <div class="lxwm">
                  {{ base.alert_bd || "" }}
                  <!-- <span class="phone">0757-22605807</span> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="to-top" @click="trigger('banner')">回到顶部</div> -->
        </div>
        <div class="icon-float">
          <a href="https://www.fuligemstones.com" target="_blank">
            <img src="../assets/img/icon_right.png" />
          </a>
        </div>
      </div>

      <!-- 已购票弹框 -->
      <div v-if="isShowPay">
        <el-dialog v-model="isShowRigthPop.qgp"
                   width="587px"
                   :show-close="false"
                   :close-on-click-modal="false"
                   custom-class="pop-float-el-dialog">
          <div class="buy-ticket-yet-box">
            <img class="popup-close"
                 @click="isShowRigthPop.qgp = false"
                 src="../assets/img/sd-icon-close.png" />
            <!-- <a style="width:50px;height:50px;display: block;" href="" download="http://icatest.pi-share.com//uploads/20220616150006_VjrY9.jpg" class="download"></a> -->
            <img class="buy-ticket-img"
                 :src="base.pc_vip_image"
                 alt="" />
            <div class="buy-exhibition-box">
              <div class="buy-exhibition">
                <img class="vip-img"
                     src="../assets/img/vip.png"
                     alt="vip" />
                <div class="name">{{ userinfo.name || "" }}</div>
                <img @click="downs()"
                     class="yxz-img"
                     src="../assets/img/yxz.png"
                     alt="yxz" />
              </div>
            </div>
          </div>
        </el-dialog>
      </div>

      <!-- 右侧弹框遮罩 -->
      <div v-show="isShowPopShade"
           @click="shadeTrigger"
           class="pop-shade"></div>
      <!-- 底部 -->
    </div>
    <footer class="footer scale"
            ref="footer"
            :style="{'position':screenHeight==0?'relative':'fixed'}">
      <div class="info">
        <img src="../assets/img/sd-dhlogo.png"
             alt="logo" />
        <div class="ml40">{{ base.copyright_add || "" }}</div>
        <!-- <div class="ml40">{{ base.copyright_phone || "" }}</div> -->
        <div class="ml40">{{ base.copyright_email || "" }}</div>
        <div class="ml50">
          {{ base.copyright }} ｜ <span style="cursor:pointer"
                @click="privacyAgreement = true">{{ contentBox.lang[24]
            }}</span>

        </div>
      </div>
    </footer>

    <!-- 隐私协议弹框 -->
    <el-dialog v-model="privacyAgreement"
               width="800px"
               :show-close="false"
               :close-on-click-modal="false"
               custom-class="pop-float-el-dialog">
      <div class="privacy-agreement">
        <img class="popup-close"
             @click="privacyAgreement = false"
             src="../assets/img/sd-icon-close.png" />
        <!-- <div class="title">隐私协议</div> -->
        <div class="box1">
          <div v-html="ysxy"></div>
        </div>
      </div>
    </el-dialog>

    <!-- 登录组件 -->
    <loginModule @hideLogin="isShowLogin = false"
                 @showRegister="showRegister"
                 @getUserInfo="getUserInfo"
                 :language="language"
                 :lang="contentBox.lang"
                 :isShowLogin="isShowLogin"></loginModule>

    <!-- 注册组件 -->
    <registerModule @hideRegister="isShowRegister = false"
                    @showLogin="showLogin"
                    :language="language"
                    :lang="contentBox.lang"
                    :isShowRegister="isShowRegister">
    </registerModule>

    <!-- 忘记密码组件 -->
    <forgotPassword @hideForgotPassword="isShowForgotPassword = false"
                    :isShowForgotPassword="isShowForgotPassword"
                    :lang="contentBox.lang"
                    @showLogin="showLogin">
    </forgotPassword>

  </div>
</template>

<script>
import { send, captcha, login, login_time } from "../api/common";
import { ElMessage } from "element-plus";
import loginModule from "@/components/login"; //中英登录
import { Share } from 'vue3-social-share';
import 'vue3-social-share/lib/index.css'
import registerModule from "@/components/register"; //中英注册
import forgotPassword from "@/components/forgotPassword"; //英文忘记密码
import { index, alipay, wechat, paypal, isPay } from "../api/common"; // api
export default {
  name: "broadcast",
  components: {
    Share
  },
  data () {
    return {
      videoSrc: "",
      // 直播id\statr
      live_url: null,
      state: null,
      subject: '',
      // 页面高度
      clientHeight: "",

      userinfo: JSON.parse(window.localStorage.getItem("userinfo")) || null,
      isShowRigthPop: {
        fx: false,
        zqzx: false,
        qgp: false,
        pwzx: false,
      },
      isShowFloatRight: true, // 浮窗右
      isShowLogin: false, // 登录
      isShowRegister: false, // 注册
      isShowForgotPassword: false, //忘记密码
      language: "CN", //默认展示中文
      base: {
        register: "注册",
        login: "登录",
        out: "退出",
        moneySymbol: "¥",
        index: "首页"
      }, //所有标题
      contentBox: {
        lang: []
      }, //内容集合
      dhycTabCutImg: null, //大会议程切换默认展示图片
      dhycTabCutIndex: 0, //大会议程切换默认展示索引
      isShowPay: 0, //购票页面展示类型 0 展示未购票 1展示已购票,支付成功后要更改这个状态
      payReceipt: false, //支付后展示用的
      privacyAgreement: false, //隐私协议弹框
      payQrCode: false, //支付二维码
      payurl: "",//支付url
      screenHeight: ''
    };
  },
  watch: {
    language () {
      this.getIndexData();
      if (this.language == "CN") {
        this.base.register = "注册";
        this.base.login = "登录";
        this.base.out = "退出";
        this.base.moneySymbol = "¥";
        this.base.index = "首页";
      } else {
        this.base.register = "Register";
        this.base.login = "Login";
        this.base.out = "Quit";
        this.base.moneySymbol = "$";
        this.base.index = "index";
      }
    },
  },
  created () {
    this.live_url = this.$route.query.live_url;
    this.state = this.$route.query.state;
    this.subject = this.$route.query.subject;
    console.log((this.screenHeight), 'aaa')
    this.clientHeight = "720px";

    let _this = this;
    _this.getIndexData();
    if (window.innerWidth > 1350 && window.innerWidth < 1450) {
      this.zoom = 0.95;
    }
    if (window.innerWidth < 1350) {
      this.zoom = 0.85;
    }
  },
  mounted () {
    if (!this.userinfo) {
      this.toindex();
      return;
    }
    this.videoSrc =
      "https://live.vhall.com/v3/lives/embedclient/watch/" +
      this.live_url +
      "?nickname=" +
      this.userinfo.name +
      "&k=11&state=11";
    // this.videoSrc = 'https://e.vhall.com/v3/lives/watch/'+this.live_url+'?email='+this.userinfo.email+'&name='+this.userinfo.name;
    this.login_time()
    if (document.body.clientHeight < 93 + 50 + 553) {
      this.screenHeight = 0
    } else {
      this.screenHeight = (document.body.clientHeight - 93 - 50 - 553) / 2
    }
  },
  methods: {
    login_time () {
      var that = this
      this.logintimer = setInterval(() => {
        login_time(that.userinfo.id).then((res) => {
          if (res.body != that.userinfo.login_time) {
            clearInterval(this.logintimer);
            this.$alert(this.contentBox.lang[25], this.contentBox.lang[6], {
              distinguishCancelAndClose: true,
              showClose: false,
              cancelButtonText: this.contentBox.lang[8],
            })
              .then(() => {
                window.localStorage.setItem("userinfo", null);
                that.userinfo = null;
                that.refresh();
              })
              .catch(() => { });
          }
        });
      }, 2500);
    },
    // 获取首页数据
    getIndexData () {
      index(this.language.toLowerCase()).then((res) => {
        let result = [];
        let { banner, base, dhbj, dhjb, dhjj, dhld, hzhb, ica, video, lang } =
          res.body;

        var size = 8;
        for (var x = 0; x < Math.ceil(dhjb.length / size); x++) {
          var start = x * size;
          var end = start + size;
          result.push(dhjb.slice(start, end));
        }
        base.dhld_title = base.dhld_title.split(",")
        dhjb = result; //重新复制给dhjb
        for (var b = 0; b < dhld.length; b++) {
          if (dhld[b].title.indexOf(",") != -1) {
            var dhldArr = dhld[b].title.split(",")
            dhld[b].title = dhldArr[0]
            dhld[b].title1 = dhldArr[1]
          } else {
            dhld[b].title1 = ''
          }
        }
        this.liveList = banner;
        this.base = Object.assign(this.base, base); //标题等
        this.contentBox = {
          dhbj,
          dhjb,
          dhjj,
          dhld,
          hzhb,
          ica,
          lang
        };
        this.ysxy = base.ysxy
        this.videList = video;
        this.dhycTabCutImg = base.tab1_pc_address; //大会议程先默认展示第一张图
        // this.videoSrc = this.videList.length ? this.videList[0].video : "";
        // this.videoCover = this.videList.length ? this.videList[0].pc_image : "";
      });
    },






    // 回到首页
    toindex () {
      this.$router.replace({ path: "/" });
    },
    // 刷新当前页面
    refresh () {
      document.location.reload();
      clearInterval(this.countTimer);
    },
    // 跳转-不新启页面
    openUrl (url) {
      window.location.href = url;
    },
    // 跳转-新启页面
    openNewUrl (url) {
      window.open(url);
    },
    // 回到顶部
    toTop () {
      scrollTo(0, 0);
    },
    goOff () { this.$router.back() },
    // 支付完成
    payDone () {
      let that = this,
        uid = that.userinfo.id;
      isPay(uid).then((res) => {
        if (!res.code) {
          // 已经支付的不再他支付
          if (res.body.is_pay == 1) {
            that.payReceipt = false;
            that.isShowPay = 1; //购票状态改成已购票
          } else {
            that.payReceipt = false;
          }
        }
      });
    },
    // 登录组件传递过来数据
    getUserInfo (e) {
      this.userinfo = e;
    },
    // 触发遮罩关闭侧边弹框
    shadeTrigger () {
      let that = this;
      for (let i in that.isShowRigthPop) {
        that.isShowRigthPop[i] = false;
      }
      that.isShowPopShade = false;
    },
    // 关闭注册或忘记密码，打开登录
    showLogin () {
      this.isShowLogin = true;
    },
    // 关闭登录，打开注册或忘记密码1注册2忘记密码
    showRegister (type) {
      if (type == 2) {
        this.isShowForgotPassword = true;
      } else {
        this.isShowRegister = true;
      }
    },
    // 触发侧边弹框
    showRightPop (name) {
      let that = this;
      if (name == "qgp" && this.userinfo == null) {
        this.showLogin();
        return;
      }
      for (let i in that.isShowRigthPop) {
        that.isShowRigthPop[i] = false;
      }
      if (name == 'qgp') {
        let uid = this.userinfo.id;
        isPay(uid).then((res) => {
          if (!res.code) {
            if (res.body.is_pay == 1) {
              that.payQrCode = false;
              that.isShowPay = 1; //购票状态改成已购票
            }
          }
        });
      }
      that.isShowRigthPop[name] = true;
      that.isShowPopShade = true;
    },

    // 切换语言
    cutLang () {
      let lang = this.language;
      this.language = lang == "EN" ? "CN" : "EN";
      var langs = lang == "EN" ? '1' : '2'
      this.videoSrc =
        "https://live.vhall.com/v3/lives/embedclient/watch/" +
        this.live_url +
        "?nickname=" +
        this.userinfo.name +
        "&k=11&state=11&lang=" + langs;
      // this.videoSrc = 'https://e.vhall.com/v3/lives/watch/'+this.live_url+'?email='+this.userinfo.email+'&name='+this.userinfo.name;

    },

    downs () {
      // 主要是为了名字不重复
      var name = new Date().getTime();
      this.downloadIamge(this.base.pc_vip_image, `${name}`);
    },
    // 下载图片
    downloadIamge (imgsrc, name) {//下载图⽚地址和图⽚名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc
    },



    // 退出登录
    logout () {
      this.$confirm(this.contentBox.lang[20], {
        confirmButtonText: this.contentBox.lang[7],
        cancelButtonText: this.contentBox.lang[8],
      })
        .then(() => {
          window.localStorage.setItem("userinfo", null);
          this.userinfo = null;
          this.refresh();
        })
        .catch(() => { });
    },

  },
};
</script>
<style scoped lang="less">
@import "../assets/constant.less";
div,
h3,
h4,
img,
video,
p,
button {
  box-sizing: border-box;
}
img,
video {
  display: block;
}

.container {
  min-height: 100vh;
  // background: linear-gradient(180deg, #041431 0%, #1e4085 50%, #041431 100%);
}

/* 头部 */
.menu-top {
  position: fixed;
  left: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background: #21b697 !important;
  // padding: 24px 0;
  .home {
    color: #fff;
    margin-left: 30px;
    font-size: 16px;
    cursor: pointer;
  }
  .menu-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 24px 140px;
    .menu-logo {
      width: 224px;
      height: 44px;
      cursor: pointer;
    }

    .tab-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 30px;
      // width: 100%;
      z-index: 999;

      .ul {
        display: flex;
        flex: 1;

        .li {
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          // margin-right: 50px;
          text-align: center;
          position: relative;
          flex: 1;
          cursor: pointer;
        }

        .li-selct::after {
          position: absolute;
          content: "";
          width: 35px;
          height: 1px;
          background: #ffffff;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .cut-lab {
        width: 84px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        color: #000;
        background: #ffffff;
        border: 1px solid #70cebc;
        margin-left: 3px;
        cursor: pointer;
      }

      .ul-login {
        display: flex;
        margin-left: 20px;
        height: 45px;
        line-height: 45px;
        background: #1ea78d;
        padding: 0 24px;

        .li {
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          position: relative;
          cursor: pointer;
        }
      }
    }
  }
}

/* 内容 */
.main-content {
  width: 100%;
  background-color: transparent;
}

/* 浮窗左侧 */
.float-menu-left {
  display: none;
  position: fixed;
  left: 10px !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;

  .li-selct-1 p {
    color: #007aff !important;
  }

  .icon-float {
    position: relative;
    border-radius: 50%;
    margin-bottom: 20px;
    width: 95px;
    height: 95px;
    background: #6EDF12;
    border-radius: 50%;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      // height: 70px;
    }
  }

  .float-btns {
    .float-icons {
      padding: 55px 0 26px;
      margin-top: -65px;
      width: 95px;
      // border: 1px solid;
      // border-image: linear-gradient(
      //     48deg,
      //     #66d8db,
      //     #37faff,
      //     #92ffb1,
      //     #ffca92,
      //     #ffa3a8
      //   )
      //   10 10;
      // background: linear-gradient(0deg, #07b66a 0%, #15ccb0 100%);
      background: rgba(110, 223, 18, 0.59);
      border-radius: 0 0 10px 10px;

      // .float-icon:hover .pop-up {
      //   display: block;
      // }

      .float-icon:hover {
        p {
          color: #0eb493;
        }
      }

      .float-icon {
        cursor: pointer;
        position: relative;

        img {
          width: 24px;
          height: 24px;
          margin: 0 6px 0 8px;
          display: none;
        }

        p {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          display: flex;
          display: block;
          align-items: center;
        }

        .pop-up {
          display: none;
          width: 200px;
          background: #ffffff;
          border-radius: 10px;
          position: absolute;
          right: 110px;
          padding: 20px 24px;
          top: -110%;
          text-align: center;
          z-index: 99;
          border: 1px solid #07b66a;

          .keep-out {
            width: 30px;
            height: 100%;
            right: -29px;
            position: absolute;
            top: 0;
            background: rgba(0, 0, 0, 0);
          }

          span {
            display: inline-block;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #212a40;
          }

          img {
            width: 130px;
            height: 130px;
            margin: 10px auto 0;
          }
        }

        .pop-up::after {
          position: absolute;
          content: "";
          width: 12px;
          height: 12px;
          right: -12px;
          background: url(../assets/img/jt.png) no-repeat;
          background-size: 100% 100%;
          // border-top: 7px solid transparent;
          // border-left: 12px solid #fff;
          top: 50%;
          // border-bottom: 7px solid transparent;
          transform: translateY(-50%);
        }
      }

      .float-line {
        width: 67px;
        height: 1px;
        background: #fff;
        margin: 8px auto;
      }

      .float-line-en {
        width: 67px;
        height: 1px;
        background: #fff;
        margin: 11px auto;
      }
    }

    .to-top {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin: 11px 0;
      cursor: pointer;
    }
  }
}

/* 浮窗右侧 */
.float-menu-right {
  position: fixed;
  right: 10px !important;
  top: 50%;
  // transform: translateY(-50%);
  margin-top: -80px;
  z-index: 99;

  .register—num {
    display: none;
    font-size: 16px;
    color: #fff;
    padding: 14px 0 5px;
    margin-bottom: 10px;
    text-align: center;
    background: #11a58e;
    border-radius: 10px 10px 0 0;
  }

  .icon-float {
    position: relative;
    border-radius: 50%;
    margin-bottom: 7px;
    width: 95px;
    height: 95px;
    background: #07b67e;
    border-radius: 50%;
    top: 10px;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      // height: 70px;
    }
  }

  .float-btns {
    .float-icons {
      padding: 0px 0 55px;
      margin-bottom: -60px;
      width: 95px;
      // border: 1px solid;
      // border-image: linear-gradient(
      //     48deg,
      //     #66d8db,
      //     #37faff,
      //     #92ffb1,
      //     #ffca92,
      //     #ffa3a8
      //   )
      //   10 10;
      background: linear-gradient(0deg, #07b66a 0%, #15ccb0 100%);
      border-radius: 10px 10px 0 0;

      // .float-icon:hover .pop-up {
      //   display: block;
      // }

      .float-icon:hover {
        p {
          color: #009be7;
        }
      }

      .float-icon {
        cursor: pointer;
        position: relative;

        img {
          width: 18px;
          height: 18px;
          margin: 0 1px;
          margin-left: 5px;
          // display: none;
        }

        p {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;
        }

        // 已购票
        .buy-exhibition {
          display: flex;
          align-items: center;

          .vip-img {
            width: 33px;
            height: 31px;
          }

          .yxz-img {
            width: 35px;
            height: 25px;
          }
        }

        // 购票
        .buy-ticket-box {
          width: 567px !important;
          background: #ffffff;
          border-radius: 10px;
          padding: 15px !important;

          .title {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
          }

          .buy-ticket-img {
            width: 100%;
            height: 180px;
            margin: 15px auto 0;
          }

          .buy-way {
            width: 100%;
            height: 118px;
            background: #f4f4f4;
            border-radius: 4px;
            padding: 16px 20px;
            display: flex;

            .left {
              width: 70%;

              .t,
              .c {
                font-size: 14px;
                font-weight: 500;
                color: #666;
              }

              .t {
                text-align: left;
              }

              .p {
                font-size: 18px;
                font-weight: bold;
                color: #ff6045;
                margin-top: 20px;
              }
            }

            .cut-off {
              width: 1px;
              height: 76px;
              background: #dfdfdf;
              margin: 0 40px;
            }

            .right {
              .t {
                font-size: 12px;
                font-weight: 500;
                color: #999999;
                justify-content: center;
              }

              .b {
                margin-top: 34px;
                display: flex;

                img {
                  width: 34px;
                  height: 34px;
                  margin: 0 13px;
                }
              }
            }
          }
        }

        .pop-up {
          display: block;
          width: 240px;
          background: #ffffff;
          border-radius: 10px;
          position: absolute;
          right: 130px;
          padding: 20px 24px;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          border: 1px solid #07b66a;

          .keep-out {
            width: 30px;
            height: 100%;
            right: -29px;
            position: absolute;
            top: 0;
            background: rgba(0, 0, 0, 0);
          }

          span {
            display: inline-block;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #212a40;
          }

          img {
            width: 130px;
            height: 130px;
            margin: 10px auto 0;
          }

          .share-link {
            height: 27px;
            border: 1px solid #1ea78d;
            border-radius: 5px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            line-height: 27px;
            margin-bottom: 10px;

            .flexq {
              padding: 7px;
              font-size: 12px;
              color: #666666;
              width: 80%;
              .text-ellipsis-line(1);
            }

            .fz {
              display: inline-block;
              width: 32px;
              height: 25px;
              background: #1ea78d;
              border-radius: 0px 4px 4px 0px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
              text-align: center;
              margin-left: -5px;
            }
          }
        }
        .aaaa.pop-up {
          padding: 20px 10px !important;
        }
        .pop-up::after {
          position: absolute;
          content: "";
          right: -12px;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          background: url(../assets/img/jt.png) no-repeat;
          background-size: 100% 100%;
        }
      }

      .float-line {
        width: 80px;
        height: 1px;
        background: #fff;
        margin: 8px auto;
      }
    }

    .to-top {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin: 11px 0;
      cursor: pointer;
    }
  }

  .lxwm {
    font-size: 14px;
    color: #666666;
    font-weight: 500;
    margin-top: 20px;

    .phone {
      font-size: 14px !important;
      color: #008acd !important;
      margin-left: 5px;
    }
  }
}

.wx-pay-code {
  padding: 30px 30px 40px;

  .popup-close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .title {
    font-size: 16px;
    text-align: center;
  }

  .box {
    text-align: center;
    margin-top: 30px;
  }
}

// 隐私协议弹框
.privacy-agreement {
  padding: 20px;

  .popup-close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .title {
    font-size: 16px;
    text-align: center;
  }

  .box1 {
    margin-top: 30px;
    max-height: 70vh;
    overflow-x: scroll;
  }
}

// 已支付回调
.pay-receipt-box {
  width: 500px;
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 20px 20px;

  .popup-close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .title {
    font-size: 16px;
    text-align: center;
  }

  .box {
    text-align: center;
    margin-top: 40px;
  }
}

// 已经购票
.buy-ticket-yet-box {
  width: 587px;
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 20px 15px;

  .popup-close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .buy-ticket-img {
    width: 100% !important;
    height: 180px !important;
  }

  .buy-exhibition-box {
    width: 100%;
    height: 83px;
    background: #f4f4f4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 20px;

    .buy-exhibition {
      display: flex;
      align-items: center;
      justify-content: end;

      .vip-img {
        width: 33px;
        height: 31px;
      }

      .name {
        font-size: 20px;
        color: #333333;
        margin-left: 20px;
      }

      .yxz-img {
        width: 35px;
        height: 25px;
        margin: 0 30px 0 60px;
      }
    }
  }
}

/* 底部 */
.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  .map {
    width: 100%;
    // height: 262px;
  }

  .info {
    width: 100%;
    background: #21b697;
    opacity: 0.9;
    .mid-center();
    align-items: center;
    // padding: 104px 0 80px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 12px;

    .ml40 {
      margin-left: 40px;
    }

    .ml50 {
      margin-left: 50px;
    }

    img {
      width: 150px;
      height: 29px;
    }
  }
}

.pos {
  position: relative;
  height: 40px;
  line-height: 40px;
  padding-left: 24px;

  .borl,
  .borr {
    position: absolute;
    width: 1px;
    height: 40px;
    top: 0px;
    background: #284a91;
  }

  .borl {
    left: 0px;
  }

  .borr {
    right: 0px;
  }
}

/* 内容 */
.main-content {
  width: 100%;
  width: 1160px;
  margin: 0 auto;
  height: 508px;
  // background-color: transparesnt;
  text-align: center;
}
/* 信息 */
.live-info {
  background: #2a2a2a;
  // margin-top: 30px;
  text-align: left;
  font-size: 18px;
  margin: 0 auto;
  width: 1160px;
  color: #fff;
  // background-color: #fff;
  padding: 10px 30px;
  line-height: 25px;
  // line-height: 48px;
  display: flex;
  // align-items: center;
  // justify-content: space-between;
  span {
    color: #fff;
    background: linear-gradient(93deg, #183dc9, #0092d8);
    display: inline-block;
    padding: 0px 8px;
    border-radius: 5px;
    margin-left: 30px;
    font-size: 18px;
    height: 35px;
    line-height: 35px;
  }
}
.placeholder {
  width: 100%;
  height: 93px;
}
</style>
<style lang="less">
@import "../assets/constant.less";
.report .swiper {
  padding-bottom: 44px;
}
.report .swiper-horizontal > .swiper-pagination-bullets,
.report .swiper-pagination-bullets.swiper-pagination-horizontal,
.report .swiper-pagination-custom,
.report .swiper-pagination-fraction {
  bottom: 0;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 43px;
  height: 5px;
  border-radius: 5px;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  background: #ffffff;
  opacity: 1;
  height: 5px;
  margin: 0 16px;
}
.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  opacity: 1;
  background: #26cdfa;
}
.pop-shade {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 39px;
}

.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
.pop-float-el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.pop-float-el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
.popup-topic-dialog {
  background-color: transparent !important;
}
.popup-menu-top {
  background: linear-gradient(0deg, #080d38, #0b4971, #050b3e) !important;
  width: 100%;
}

.el-table thead tr {
  background: linear-gradient(93deg, #183dc9, #0092d8) !important;
  height: 58px;
  border-radius: 5px 5px 0px 0px;
  color: #fff;
  font-size: 16px;
}
.el-table tbody {
  background: linear-gradient(#113580, #001f6e);
}
.el-table tbody tr {
  height: 72px;
  background: rgba(0, 0, 0, 0);
  color: #fff;
  font-size: 16px;
}
.el-table tbody tr td {
  background: rgba(0, 0, 0, 0) !important;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #113580 !important;
}
.el-table th.el-table__cell {
  background: rgba(0, 0, 0, 0) !important;
}
body .el-table__inner-wrapper::before {
  z-index: inherit;
}
::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #999999;
}
// @media (max-width: 1750px) {
//   /* 内容 */
//   .main-content {
//     width: 1227px !important;
//     height: 543px;
//   }
// }
// @media (max-width: 1600px) {
//   /* 内容 */
//   .main-content {
//     height: 543px;
//   }
// }
iframe {
  border: medium none;
}
.social-share .icon-wechat .wechat-qrcode,
.social-share .icon-wechat .wechat-qrcode {
  height: auto !important;
}
.social-share .icon-wechat .wechat-qrcode canvas {
  display: block !important;
  margin: 0 auto !important;
  margin-top: 5px !important;
}
</style>
